import React, { useEffect } from 'react';
import small from './assets/img/small.svg';
import time from './assets/img/time.svg';
import javascript from './assets/img/javascript.svg';
import down from './assets/img/down.svg';
import './App.css';
import Granim from 'granim';
import Header from './components/Header';
import ButtonUI from './components/ButtonUI';
import Why from './components/Why';
import How from './components/How';
import { useTranslation } from 'react-i18next';

function App() {
  const [t, i18n] = useTranslation();

  useEffect(() => {
    new Granim({
      element: '#granim-canvas',
      name: 'granim',
      opacity: [1, 1],
      states: {
        'default-state': {
          gradients: [
            ['#0A101E', '#1c2b50'],
            ['#1c2b50', '#460061'],
          ],
          transitionSpeed: 5000,
        },
      },
    });
  });

  // ['#0f172a', '#111'],
  return (
    <div className="p-1 ">
      <canvas id="granim-canvas" />
      <Header />
      <div className="container flex flex-col justify-center  items-center mt-36 ">
        <h1 className="text-[2.5rem]  text-center max-w-[1000px]">
          {document.body.classList.contains('arabic') && 'sky.js'}
          {t('content.h1')}
        </h1>
        <p className="text-sm mt-10 leading-7">
          <img className="inline" src={javascript} alt="" /> - {t('content.builtWithJavaScript')}{' '}
          <br />
          <img className="inline" src={small} alt="" /> - {t('content.smallSize')}
          <br />
          <img className="inline" src={time} alt="" /> - {t('content.reRender')}
        </p>
        <div className="flex justify-center items-center p-1 mt-10">
          <ButtonUI text={t('content.Get')} css={'rtl:px-10'} />
          <ButtonUI text={t('content.Start')} />
        </div>
      </div>
      <div className="container flex justify-between items-center mt-28">
        <a href="#How" className="question text-2xl">
          {t('content.How')}
        </a>
{/* <div className="flex flex-col justify-center items-center">
          <a href="#down" className="py-2 border border-white rounded-full ">
            <img src={down} alt={''} id={'down'} />
          </a>
          <span className="mt-3 text-xs">{t('content.Scroll-Down')}</span>
        </div> */}
        <a href="#Why" className=" question text-2xl">
          {t('content.Why')}
        </a>
      </div>
        <Why />
        <How />

{/* <div className="container">
        <footer className="absolute bottom-0 px-1 py-4  bg-black">
          @Sky.js All Rights Reserved - {new Date().getFullYear()}
        </footer>
      </div> */}
    </div>
  );
}

export default App;
