const Button = (props) => {
  const { text, func, css, icon, alter, cssImage } = props;
  return (
    <>
      <button
        className={`${css} flex  p-1 rounded-md  items-center `}
        onClick={func}>
        <img src={icon} alt={alter} className={`w-6 m-1 ${cssImage}`} />
        <span className="text-sm text-center ">{text}</span>
      </button>
    </>
  );
};

export default Button;
