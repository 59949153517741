const Why = () => {
  return <div>
    <h1>Why Sky.js?</h1>
  <h4>
      There Are Many JavaScript UI Libraries/Frameworks. Everyone Chooses The Best Choice According To Their Uses, If Your Choice Is Storing Memories, Real-Time Rendering, UI Utilities, Small Package Size With High-Performance So Sky.js Is For You.
      </h4>
      </div>
};

export default Why;
