const ButtonUI = (props) => {
  const { text, css } = props;
  return (
    <>
      <button
        id={'button'}
        className={` ${css} text-2xl py-2 px-4 m-5  border-2 border-purple hover:border-blue rounded-[5px]`}>
        {text}
      </button>
    </>
  );
};

export default ButtonUI;
