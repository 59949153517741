import React, { useState } from 'react';
import Button from './Button';
import logo from '../assets/img/logo.svg';
import Twitter from '../assets/img/twitter.svg';
import light from '../assets/img/light.svg';
import dark from '../assets/img/dark.svg';
import langIcon from '../assets/img/lang.svg';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const [t, i18n] = useTranslation();
  const [lang, setLang] = useState(false);
  const [mode, setMode] = useState(false);
  return (
    <header className="header-border container my-10  drop-shadow-primary drop-shadow-lg rounded-lg py-3 px-10 border-white bg-[#01091bf5] flex items-center justify-between">
      <img
        id="logo"
        src={logo}
        className="w-20  rounded-[50%] cursor-pointer "
        alt="Sky Logo"
      />

      <h1 className="text-3xl p-1 font-bold">
        <span className="text-purple">S</span>
        <span className="text-white">k</span>
        <span className="text-blue">y</span>
        <span className="text-yellow">.js</span>
      </h1>

      <div className="flex items-center ">
        <div className="mr-10 -ml-10 rtl:ml-10 rtl:-mr-10 flex items-center">
          <Button
            icon={mode ? dark : light}
            func={() => {
              setMode(!mode);
            }}
            css={`mx-[5px]
              ${mode ? 'bg-[#222]' : 'bg-yellow'}
            `}
          />
          <Button
            icon={langIcon}
            func={() => {
              setLang(!lang);
              !lang ? i18n.changeLanguage('ar') : i18n.changeLanguage('en');
              !lang ? (document.body.dir = 'rtl') : (document.body.dir = 'ltr');
            }}
            css={'shadow-md hover:shadow-[#1c2b50] hover:bg-[#0f172a] mx-[5px]'}
          />
        </div>
        <Button
          icon={Twitter}
          text={'Twitter'}
          func={() => {
            window.location.assign('https://twitter.com/ui_skyjs');
          }}
          css={'mx-2 border rounded-lg twitter-button py-1 px-3'}
          cssImage={'mr-2'}
        />
      </div>
    </header>
  );
};

export default Header;
