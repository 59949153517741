const How = () => {
  return <div>
    <h1>How It Works?</h1>
  <h4>
Well. it Saves The Memories Inside The Library File So It Be Recovered Anywhere And Anytime, And Rendering All The Memories In Real-Time When It Created, Updated Or Deleted.
      </h4>
      </div>
};

export default How;
